/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AuthService from "../../base/services/authentication.service";
import "./custom.css";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import LastPage from "./messagePages/LastPage";
import RequestPage from "./messagePages/RequestPage";
import AuthLayout from "./layout/AuthLayout";
import Login from "./Login";
import Registration from "./Registration";
import RegistrationStep2 from "./RegistrationStep2";
import RegistrationStep3 from "./RegistrationStep3";
import ConfirmCode from "./ConfirmCode";
import VerificationCodeAndSignIn from "./VerificationCodeAndSignIn";
import ForgotPasswordConfirm from "./ForgotPassword/ForgotPasswordConfirm";
import "../../assets/styles/login-1.css";
import RegistrationOfNationalOrganization from "./NgoRegistration/RegistrationOfNationalOrganization";
import RegistrationOfInternationalDonor from "./RegistrationOfInternationalDonor";
import RegistrationOfVendor from "./RegistrationOfVendor";
import RegistrationOfHumanitarian from "./RegistrationOfHumanitarian";
import LayoutBackgroundBox from "./layout/LayoutBackgroundBox";
import RegisterOfHumanitarianStepThree from "./RegisterOfHumanitarianStepThree";
import RegisterOfHumanitarianStepFour from "./RegistrationHumanitarianStepFour";
import NgoVerification from "./NgoRegistration/NgoVerification";
import FinalStepNgo from "./NgoRegistration/FinalStepNgo";
import ResetPassword from "./ForgotPassword/ResetPassword";
import RecoveredPassword from "./ForgotPassword/RecoveredPassword";
/* route protection will be applied after regist / auth apis are connected */

import LoginForAfrika from './forAfricaLogin/LoginForAfrica';
import LoginKosovo from './kosovoLogin/LoginKosovo';
import LoginKAS from "./KAS/LoginKAS";

export function AuthPage() {
   const [isAuthorized, setisAuth] = useState(false);

   const location = useLocation();

   useEffect(() => {
      setisAuth(AuthService.isAuthenticated());
   }, [location, setisAuth]);

   return (
      <>
         <Routes>
            <Route path="/" element={<Navigate to="/auth/login" />} />
            {isAuthorized ? (
               /*Render auth page when user at `/auth` and not authorized.*/
               <Route path="/*" element={<Navigate to="/" />} />
            ) : (
               <>
                  <Route path="/auth/registration" element={<Registration />} />
                  <Route element={<AuthLayout />}>
                     <Route
                        path="/auth/national-org-registration"
                        element={<RegistrationOfNationalOrganization />}
                     />
                     <Route
                        path="/auth/international-donor-registration"
                        element={<RegistrationOfInternationalDonor />}
                     />
                     <Route
                        path="/auth/humanitarian-expert-registration"
                        element={<RegistrationOfHumanitarian />}
                     />
                     <Route
                        path="/auth/humanitarian-registration-step-three"
                        element={<RegisterOfHumanitarianStepThree />}
                     />
                     <Route
                        path="/auth/humanitarian-registration-step-four"
                        element={<RegisterOfHumanitarianStepFour />}
                     />
                     <Route path="/auth/vendor-registration" element={<RegistrationOfVendor />} />
                     <Route path="/auth/register-organization" element={<RegistrationStep2 />} />
                     <Route path="/auth/request-approval" element={<RegistrationStep3 />} />
                  </Route>
                  <Route element={<LayoutBackgroundBox />}>
                     <Route
                        path="/auth/national-org-registration"
                        element={<RegistrationOfNationalOrganization />}
                     />
                     <Route
                        path="/auth/international-donor-registration"
                        element={<RegistrationOfInternationalDonor />}
                     />
                     <Route path="/auth/vendor-registration" element={<RegistrationOfVendor />} />
                     <Route path="/auth/register-organization" element={<RegistrationStep2 />} />
                     <Route path="/auth/request-approval" element={<RegistrationStep3 />} />
                  </Route>
                  {
                     process.env.PUBLIC_URL == "https://forafrika.kaporg.com" ? (
                        <>
                           <Route path="/auth/login" element={<LoginForAfrika />} />
                           <Route element={<LayoutBackgroundBox />}>
                              <Route path="/auth/confirm-code" element={<ConfirmCode />} />
                              <Route path="/auth/confirm-request" element={<VerificationCodeAndSignIn />} />
                              <Route path="/auth/forgot-password" element={<ForgotPassword />} />
                              <Route path="/auth/password-confirm" element={<ForgotPasswordConfirm />} />

                              <Route
                                 path="/auth/national-org-registration/verification"
                                 element={<NgoVerification />}
                              />
                              <Route
                                 path="/auth/national-org-registration/step-three"
                                 element={<FinalStepNgo />}
                              />
                              <Route path="/auth/reset-password" element={<ResetPassword />} />
                              <Route path="/auth/reset-password-recovered" element={<RecoveredPassword />} />
                           </Route>
                        </>
                     ) : process.env.PUBLIC_URL == "https://kosovo.kaporg.com" ? (
                        <>
                           <Route path="/auth/login" element={<LoginKosovo />} />
                           <Route element={<LayoutBackgroundBox />}>
                              <Route path="/auth/confirm-code" element={<ConfirmCode />} />
                              <Route path="/auth/confirm-request" element={<VerificationCodeAndSignIn />} />
                              <Route path="/auth/forgot-password" element={<ForgotPassword />} />
                              <Route path="/auth/password-confirm" element={<ForgotPasswordConfirm />} />

                              <Route
                                 path="/auth/national-org-registration/verification"
                                 element={<NgoVerification />}
                              />
                              <Route
                                 path="/auth/national-org-registration/step-three"
                                 element={<FinalStepNgo />}
                              />
                              <Route path="/auth/reset-password" element={<ResetPassword />} />
                              <Route path="/auth/reset-password-recovered" element={<RecoveredPassword />} />
                           </Route>
                        </>
                     ) : process.env.PUBLIC_URL == "https://ask.kaporg.com" ? (
                        <>
                           <Route path="/auth/login" element={<LoginKAS />} />
                           <Route element={<LayoutBackgroundBox />}>
                              <Route path="/auth/confirm-code" element={<ConfirmCode />} />
                              <Route path="/auth/confirm-request" element={<VerificationCodeAndSignIn />} />
                              <Route path="/auth/forgot-password" element={<ForgotPassword />} />
                              <Route path="/auth/password-confirm" element={<ForgotPasswordConfirm />} />

                              <Route
                                 path="/auth/national-org-registration/verification"
                                 element={<NgoVerification />}
                              />
                              <Route
                                 path="/auth/national-org-registration/step-three"
                                 element={<FinalStepNgo />}
                              />
                              <Route path="/auth/reset-password" element={<ResetPassword />} />
                              <Route path="/auth/reset-password-recovered" element={<RecoveredPassword />} />
                           </Route>
                        </>
                     ) : (
                        <Route element={<LayoutBackgroundBox />}>
                           <Route path="/auth/login" element={<Login />} />
                           <Route path="/auth/confirm-code" element={<ConfirmCode />} />
                           <Route path="/auth/confirm-request" element={<VerificationCodeAndSignIn />} />
                           <Route path="/auth/forgot-password" element={<ForgotPassword />} />
                           <Route path="/auth/password-confirm" element={<ForgotPasswordConfirm />} />

                           <Route
                              path="/auth/national-org-registration/verification"
                              element={<NgoVerification />}
                           />
                           <Route
                              path="/auth/national-org-registration/step-three"
                              element={<FinalStepNgo />}
                           />
                           <Route path="/auth/reset-password" element={<ResetPassword />} />
                           <Route path="/auth/reset-password-recovered" element={<RecoveredPassword />} />
                        </Route>
                     )
                  }

                  {/* 
                     <Route path="/auth/confirm-code" element={<ConfirmCode />} />
                     <Route path="/auth/confirm-request" element={<VerificationCodeAndSignIn />} />
                     <Route path="/auth/register-organization" element={<RegistrationStep2 />} />
                     <Route path="/auth/request-approval" element={<RegistrationStep3 />} />
                  </Route> */}
                  <Route path="/auth/success" element={<LastPage />} />
                  <Route path="/auth/request" element={<RequestPage />} />
               </>
            )}
         </Routes>
      </>
   );
}
