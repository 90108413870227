import Header from "./components/Header";
import FirstSection from "./components/FirstSection";
import SecuringFunds from "./components/SecuringFunds";
import React from "react";
import SocialChange from "./components/SocialChange";
import Development from "./components/Development";
import Resources from "./components/Resources";
import Footer from "./components/Footer";
function LoginKosovo() {
   return (
      <div className="App">
         <Header />
         <FirstSection />
         <SecuringFunds />
         <SocialChange />
         <Development />
         <Resources />
         <Footer />
      </div>
   );
}

export default LoginKosovo;
