import React from "react";
import "./styles.scss";
import ceoIcon from "./assets/ceo.svg";
import accountsDepartmentIcon from "./assets/accountsDepartment.svg";
import economicStatisticsIcon from "./assets/economicStatistics.svg";
import socialStatisticsIcon from "./assets/socialStatistics.svg";
import agricultureIcon from "./assets/agriculture.svg";
import registrationSurveyIcon from "./assets/registrationSurvey.svg";
import methodologyIcon from "./assets/methodology.svg";
import administrationSupportIcon from "./assets/administrationSupport.svg";

const departments = [
  { icon: ceoIcon, title: "Chief Executive Officer" },
  { icon: accountsDepartmentIcon, title: "National Accounts Department" },
  { icon: economicStatisticsIcon, title: "Department of Economic Statistics" },
  { icon: socialStatisticsIcon, title: "Department of Social Statistics" },
  {
    icon: agricultureIcon,
    title: "Department of Agriculture and Environment Statistics",
  },
  {
    icon: registrationSurveyIcon,
    title: "Department of Registration and Survey",
  },
  {
    icon: methodologyIcon,
    title: "Department of Methodology and Information Technology",
  },
  {
    icon: administrationSupportIcon,
    title: "Department of Administration and Support",
  },
];

const IconGrid = () => {
  return (
    <div className="grid-container-ASK">
      {departments.map((dept, index) => (
        <div className="grid-item" key={index}>
          <img src={dept.icon} alt="" />
          <div className="title">{dept.title}</div>
        </div>
      ))}
    </div>
  );
};

export default IconGrid;
