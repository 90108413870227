/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getOrganizationList } from "../../../../../app/pages/workspace/results/selectors";
import { checkIsActive } from "../../../../_helpers";
import { UserRolesEnum } from "../../../../../app/pages/auth/userRoles.enum";
import MenuAssesmentList from "./components/MenuAssesmentList";
import MenuAssesmentListNew from "./components/MenuAssesmentListNew";
import MenuResultsList from "./components/MenuResultsList";
import MenuResultsListSuperadmin from "./components/MenuResultsListSuperadmin";

import MenuOrganizationList from "./components/MenuOrganizationList";
import { httpService } from "../../../../../app/base/services/httpService.service";
import AuthService from "../../../../../app/base/services/authentication.service";
import { SessionService } from "../../../../../app/base/services/session.service";
import RequestModal from "../../../../../app/pages/workspace/assesments/components/requestModal";
import OrganizationMenuButtons from "./components/OrganizationMenuButtons";
import NoDataMessage from "../../../../../app/components/noDataMessage/NoDataMessage";
import MenuActionPlanList from "./components/MenuActionPlanList";
import MenuActionPlanListView from "./components/MenuActionPlanListView";
import {
   fetchOrganizationList,
   setOrganizationList
} from "../../../../../app/pages/workspace/results/resultSlice";
import "./custom.scss";
import { useDispatch } from "react-redux";
import { editDataStore } from "../../../../../app/pages/admin/survey/surveyBuilder/v2/surveyStore";
import MenuAdminActionPlanList from "./components/MenuAdminActionPlanList";
import { useAssessmentStore } from "../../../../../app/pages/admin/assessment/assessmentStore";

export function AsideMenuList({ data, api, kind, layoutProps, activeMenu }) {
   const dispatch = useDispatch();
   const location = useLocation();
   const organizationId = AuthService.getUser()?.organizationId;
   const isViewActionPlan =
      location.pathname.includes("/view") && location.pathname.includes("action-plan");
   const isSuperAdmin = AuthService.isAuthorized([UserRolesEnum.SuperAdmin]);
   const { organizationList } = useSelector((state) => ({
      organizationList: getOrganizationList(state)
   }));

   const [orgFilteredData, setOrgFilteredData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [assesmentList, setAssesmentList] = useState();
   const [resultList, setResultList] = useState();
   const [showRequestModal, setShowRequestModal] = useState(false);
   const [isSorted, setIsSorted] = useState(false);
   const [typeAssessment, setTypeAssessment] = useState("");
   const [actionPlanData, setActionPlanData] = useState([]);
   const [adminActionPlanData, setAdminActionPlanData] = useState([]);
   const isSurveyInWorkspace = editDataStore((state) => state.isSurveyInWorkspace);
   const { isSetRequest, setRequest } = useAssessmentStore();

   const getMenuItemActive = (url, hasSubmenu = false) => {
      return checkIsActive(location, url)
         ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
         : "";
   };

   const sortOrganizationOrder = () => {
      function compare(a, b) {
         if (!isSorted) {
            if (a.title < b.title) {
               return -1;
            }
            if (a.title > b.title) {
               return 1;
            }
            return 0;
         } else {
            if (a.title > b.title) {
               return -1;
            }
            if (a.title < b.title) {
               return 1;
            }
            return 0;
         }
      }
      const data = [...organizationList];
      const sortedData = data.sort(compare);

      setTimeout(() => {
         setIsSorted(!isSorted);
         setOrgFilteredData([]);
         setOrgFilteredData(sortedData);
      }, 0);
   };

   const searchOrganization = (keyword) => {
      if (keyword.length < 2) {
         setOrgFilteredData(organizationList);
      } else {
         const res = organizationList.filter((organization) =>
            organization?.title?.toLowerCase().includes(keyword.toLowerCase())
         );
         setOrgFilteredData(res);
      }
   };

   useEffect(() => {
      if (api) {
         setIsLoading(true);
         let dummyFetch;
         let organizationData;
         let menuList = [];

         let currentWorkspaceId = SessionService.getItem("workspaceID");
         if (kind === "action-plan" && !isSuperAdmin) {
            const currentWorkspaceId = SessionService.getItem("workspaceID");

            httpService.get(
               `/ActionPlans/${currentWorkspaceId}/ngo/assessments`,
               (res) => {
                  setActionPlanData(res.data);
                  setIsLoading(false);
               },
               (error) => {
                  console.error("Error fetching Action Plan data:", error);
                  setIsLoading(false);
               }
            );
         } else if (kind === "action-plan" && isSuperAdmin && !isViewActionPlan) {
            const currentWorkspaceId = SessionService.getItem("workspaceID");

            httpService.get(
               `ActionPlans/resources/assessments?workspaceId=${currentWorkspaceId}`,
               (res) => {
                  setAdminActionPlanData(res.data);
                  setIsLoading(false);
               },
               (error) => {
                  console.error("Error fetching Action Plan data:", error);
                  setIsLoading(false);
               }
            );
         } else if (kind === "organization-list") {
            const cb = () => {
               setOrgFilteredData();
               setIsLoading(false);
            };
            dispatch(fetchOrganizationList(cb()));
         } else if (kind === "guided-assessment-list") {
            httpService.post(
               `assesment/guided/getall/${currentWorkspaceId}`,
               {},
               (res) => {
                  menuList = res.data.forEach((assesment) => {
                     if (assesment.surveys.length > 0) {
                        const assmnt = {
                           id: assesment.id,
                           type: "divider",
                           icon: "pie-chart",
                           url: null,
                           title: assesment.name,
                           consultant: assesment.consultantName,
                           role: [
                              UserRolesEnum.NationalOrganization,
                              UserRolesEnum.InternationalOrganization,
                              UserRolesEnum.SuperAdmin,
                              UserRolesEnum.Staff,
                              UserRolesEnum.Donor
                           ]
                        };
                        const surveys = assesment.surveys.map((survey) => {
                           return {
                              id: survey.id,
                              type: "item",
                              icon: "pie-chart",
                              title: survey.name,
                              progress: survey.progress,
                              url: `/guided-assessment/survey/${survey.id}`,
                              role: [
                                 UserRolesEnum.NationalOrganization,
                                 UserRolesEnum.InternationalOrganization,
                                 UserRolesEnum.SuperAdmin,
                                 UserRolesEnum.Staff,
                                 UserRolesEnum.Donor
                              ]
                           };
                        });

                        menuList.push(assmnt);
                        surveys.forEach((survey) => {
                           menuList.push(survey);
                        });
                     }
                     setAssesmentList(menuList);
                  });
                  setIsLoading(false);
                  setShowRequestModal(true);
               },
               () => {
                  setShowRequestModal(true);
               }
            );
         } else if (kind === "results") {
            httpService.get(
               !AuthService.isAuthorized([
                  UserRolesEnum.InternationalOrganization,
                  UserRolesEnum.SuperAdmin,
                  UserRolesEnum.Donor
               ]) && `/Results/${currentWorkspaceId}/ngo/assessments`,
               (res) => {
                  setResultList(res.data);
                  setIsLoading(false);
               },
               () => {
                  // setShowRequestModal(true);
               }
            );
         } else if (kind === "guided-assessment") {
            setTypeAssessment("guided-assessment");
            httpService.get(
               `/Workspaces/${currentWorkspaceId}/guided-assessments?organizationId=${organizationId}`,
               (res) => {
                  setAssesmentList(res.data);
                  setIsLoading(false);
               },
               () => {
                  // setShowRequestModal(true);
               }
            );
         } else if (kind === "self-assessment") {
            setTypeAssessment("self-assessment");
            httpService.get(
               `/Workspaces/${currentWorkspaceId}/self-assessments?organizationId=${organizationId}`,
               (res) => {
                  setAssesmentList(res.data);
                  setIsLoading(false);
               },
               () => {
                  // setShowRequestModal(true);
               }
            );
         }
      }

      // return () => {
      //    dispatch(setOrganizationList([]));
      // };=
   }, [api, kind]);

   useEffect(() => {
      if(isSetRequest) {
         let currentWorkspaceId = SessionService.getItem("workspaceID");
         setTypeAssessment("guided-assessment");
         httpService.get(
            `/Workspaces/${currentWorkspaceId}/guided-assessments?organizationId=${organizationId}`,
            (res) => {
               setAssesmentList(res.data);
               setIsLoading(false);
            }
         );
         setRequest(false);
      }
   },[isSetRequest])

   useEffect(() => {
      let currentWorkspaceId = SessionService.getItem("workspaceID");
      if (kind === "guided-assessment") {
         setTypeAssessment("guided-assessment");
         httpService.get(
            `/Workspaces/${currentWorkspaceId}/guided-assessments?organizationId=${organizationId}`,
            (res) => {
               setAssesmentList(res.data);
               setIsLoading(false);
            },
            () => {
               // setShowRequestModal(true);
            }
         );
      } else if (kind === "self-assessment") {
         setTypeAssessment("self-assessment");
         httpService.get(
            `/Workspaces/${currentWorkspaceId}/self-assessments?organizationId=${organizationId}`,
            (res) => {
               setAssesmentList(res.data);
               setIsLoading(false);
            },
            () => {
               // setShowRequestModal(true);
            }
         );
      }
   }, [isSurveyInWorkspace, api, kind]);

   useEffect(() => {
      setOrgFilteredData(organizationList);
   }, [organizationList]);

   return (
      <>
         {/* Request modal for guided assessments */}
         <RequestModal show={showRequestModal} setOpen={setShowRequestModal} />

         {organizationList?.length > 0 &&
            AuthService.isAuthorized([
               UserRolesEnum.Donor,
               UserRolesEnum.InternationalOrganization,
               UserRolesEnum.SuperAdmin
            ]) && (
               <OrganizationMenuButtons
                  searchOrganization={searchOrganization}
                  sortOrganizationOrder={sortOrganizationOrder}
               />
            )}

         {!AuthService.isAuthorized([
            UserRolesEnum.Donor,
            UserRolesEnum.SuperAdmin,
            UserRolesEnum.InternationalOrganization
         ]) &&
            organizationList.length > 0 && (
               <p className="text-dark align-center text-center">
                  This screen allows you to evaluate yourself based on the questionnaires you have
                  solved
               </p>
            )}

         {kind === "organization-list" && orgFilteredData?.length < 1 && !isLoading ? (
            <div className="no-organization-message">
               <NoDataMessage />
            </div>
         ) : null}

         {/* begin::Menu Nav */}
         <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/* module pages - static menus submenus */}
            {isLoading && <Skeleton count={3} style={{ marginBottom: 15 }} />}

            {api === null &&
               data?.length !== 0 &&
               data?.map((menuItem) => {
                  return (
                     AuthService.isAuthorized(menuItem.role) &&
                     (menuItem.type === "divider" ? (
                        <li key={menuItem.id} className="menu-section ">
                           <h4 className="menu-text">{menuItem.title}</h4>
                           <i className="menu-icon flaticon-more-v2"></i>
                        </li>
                     ) : (
                        <li
                           key={menuItem.id}
                           className={`menu-item menu-item-submenu ${getMenuItemActive(
                              menuItem.url,
                              menuItem.children?.length > 0 ? true : false
                           )}`}
                           aria-haspopup="true"
                           data-menu-toggle="hover"
                        >
                           <NavLink
                              className={`menu-link menu-toggle ${menuItem.isDisabled &&
                                 " disabled"} `}
                              to={menuItem.url}
                           >
                              <span className="svg-icon menu-icon">
                                 <i className={"green " + menuItem.icon}></i>
                              </span>
                              <span className="menu-text">{menuItem.title}</span>
                              {menuItem.children?.length > 0 ? <i className="menu-arrow" /> : null}
                           </NavLink>

                           {menuItem.children?.length > 0
                              ? menuItem.children.map((firstChild) => {
                                   return AuthService.isAuthorized(firstChild.role) ? (
                                      <div key={firstChild.id} className="menu-submenu">
                                         <i className="menu-arrow" />
                                         <ul className="menu-subnav">
                                            <li
                                               className="menu-item  menu-item-parent"
                                               aria-haspopup="true"
                                            >
                                               <span className="menu-link">
                                                  <span className="menu-text">
                                                     {firstChild.title}
                                                  </span>
                                               </span>
                                            </li>

                                            {/* Inputs */}
                                            {/*begin::2 Level*/}
                                            <li
                                               className={`menu-item menu-item-submenu ${getMenuItemActive(
                                                  firstChild.url,
                                                  firstChild.children?.length > 0 ? true : false
                                               )}`}
                                               aria-haspopup="true"
                                               data-menu-toggle="hover"
                                            >
                                               <NavLink
                                                  className={`menu-link  ${firstChild.isDisabled &&
                                                     " disabled"}  ${
                                                     firstChild.children?.length > 0
                                                        ? "menu-toggle"
                                                        : ""
                                                  }`}
                                                  to={firstChild.url}
                                               >
                                                  <div className="d-flex w-100 align-items-center">
                                                     <i
                                                        className={"green mr-1 " + firstChild.icon}
                                                     ></i>
                                                     <span className="menu-text">
                                                        {firstChild.title}
                                                     </span>
                                                  </div>
                                                  {firstChild.children?.length > 0 ? (
                                                     <i className="menu-arrow" />
                                                  ) : null}
                                               </NavLink>

                                               {/* second level child */}
                                               {firstChild.children?.length > 0 ? (
                                                  <div className="menu-submenu ">
                                                     <i className="menu-arrow" />
                                                     <ul className="menu-subnav">
                                                        {/*begin::3 Level*/}
                                                        {firstChild.children.map((secondChild) => {
                                                           return AuthService.isAuthorized(
                                                              secondChild.role
                                                           ) ? (
                                                              <li
                                                                 key={secondChild.id}
                                                                 className={`menu-item  ${getMenuItemActive(
                                                                    secondChild.url
                                                                 )}`}
                                                                 aria-haspopup="true"
                                                              >
                                                                 <NavLink
                                                                    className={`menu-link ${secondChild.isDisabled &&
                                                                       " disabled"} `}
                                                                    to={secondChild.url}
                                                                 >
                                                                    <i className="menu-bullet menu-bullet-dot">
                                                                       <span />
                                                                    </i>
                                                                    <span className="menu-text">
                                                                       {secondChild.title}
                                                                    </span>
                                                                 </NavLink>
                                                              </li>
                                                           ) : null;
                                                        })}
                                                        {/*end::3 Level*/}
                                                     </ul>
                                                  </div>
                                               ) : null}
                                            </li>
                                            {/*end::2 Level*/}
                                         </ul>
                                      </div>
                                   ) : null;
                                })
                              : null}
                        </li>
                     ))
                  );
               })}

            {/* if list is dynamic generated with api data > assesment list*/}
            {/* {api !== null &&
            (kind === "self-assessment" ||
               kind === "guided-assessment" ||
               kind === "guided-assessment-list") &&
            !isLoading ? (
               assesmentList?.length > 0 ? (
                  assesmentList?.map((menuItem, index) => {
                     return AuthService.isAuthorized(menuItem.role) ? (
                        <MenuAssesmentList menuItem={menuItem} key={index} />
                     ) : null;
                  })
               ) : (
                  <li className="menu-section ">
                     <h4 className="menu-text">There is no data available</h4>
                  </li>
               )
            ) : null} */}
            {/* If page is results */}
            {api !== null && kind === "results" && !isLoading
               ? !AuthService.isAuthorized([
                    UserRolesEnum.InternationalOrganization,
                    UserRolesEnum.SuperAdmin,
                    UserRolesEnum.Donor,
                    UserRolesEnum.Consultant
                 ]) && <MenuResultsList menuItem={resultList} kind={kind} />
               : null}
            {api !== null && kind === "results"
               ? AuthService.isAuthorized([
                    UserRolesEnum.InternationalOrganization,
                    UserRolesEnum.SuperAdmin,
                    UserRolesEnum.Donor,
                    UserRolesEnum.Consultant
                 ]) && <MenuResultsListSuperadmin setIsLoading={setIsLoading} />
               : null}
            {/* If assessment is self or guided */}
            {/* CONTINUE */}
            {api !== null &&
            (kind === "self-assessment" ||
               kind === "guided-assessment" ||
               kind === "guided-assessment-list") &&
            !isLoading ? (
               <MenuAssesmentListNew
                  menuItem={assesmentList}
                  typeAssessment={typeAssessment}
                  kind={kind}
               />
            ) : null}

            {/* if list is dynamic generated with api data > organization list */}
            {kind === "action-plan" &&
            !isViewActionPlan &&
            !isLoading &&
            AuthService.isAuthorized([
               UserRolesEnum.NationalOrganization,
               UserRolesEnum.Staff,
               UserRolesEnum.SuperAdmin
               // UserRolesEnum.Donor
            ]) ? (
               !isSuperAdmin ? (
                  <MenuActionPlanList data={actionPlanData} kind={kind} />
               ) : (
                  <MenuAdminActionPlanList data={adminActionPlanData} kind={kind} />
               )
            ) : null}
            {isViewActionPlan &&
            !isLoading &&
            AuthService.isAuthorized([
               UserRolesEnum.NationalOrganization,
               UserRolesEnum.Staff
               // UserRolesEnum.SuperAdmin,
               // UserRolesEnum.Donor
            ]) ? (
               <MenuActionPlanListView />
            ) : null}
            {api !== null &&
            kind === "organization-list" &&
            !isLoading &&
            AuthService.isAuthorized([
               UserRolesEnum.InternationalOrganization,
               UserRolesEnum.SuperAdmin,
               UserRolesEnum.Donor
            ])
               ? orgFilteredData?.map((menuItem, index) => {
                    return AuthService.isAuthorized(menuItem.role) ? (
                       <MenuOrganizationList menuItem={menuItem} key={index} />
                    ) : null;
                 })
               : null}
         </ul>

         {/* end::Menu Nav */}
      </>
   );
}
