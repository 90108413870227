import React, { useState, useEffect } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { httpService } from "../../base/services/httpService.service";
import { useDispatch } from "react-redux";
import { LocalStorageService } from "../../base/services/local-storage.service";
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router-dom";
import { registerHumanitarianUser } from "./authSlice";
import * as Yup from "yup";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { BackIcon, EyeInvisible } from "../../assets/icons";
import Toast from "../../base/components/Toast";
import { useSelector } from "react-redux";
import { Spin } from "antd";

const initialValues = {
   email: "",
   password: "",
   confirmPassword: ""
};

function RegistrationOfHumanitarian() {
   const [type, setType] = useState("password");
   const [icon, setIcon] = useState(eyeOff);

   const [confirmType, setConfirmType] = useState("password");
   const [confirmIcon, setConfirmIcon] = useState(eyeOff);

   const loading = useSelector((state) => state.auth.isLoading);

   const dispatch = useDispatch();
   const navigate = useNavigate();

   const validationSchema = Yup.object().shape({
      email: Yup.string()
         .email("Invalid email address")
         .required("Email is required"),
      password: Yup.string()
         .min(8, "Password must be at least 8 characters")
         .required("Password is required"),
      confirmPassword: Yup.string()
         .oneOf([Yup.ref("password"), null], "Passwords must match")
         .required("Confirm Password is required"),
      acceptTerms: Yup.boolean().oneOf([true], "You must accept the terms and conditions")
   });

   const onSubmit = async (values) => {
      try {
         dispatch(
            registerHumanitarianUser(values, (user) => {
               navigate(`/auth/confirm-code?email=${encodeURIComponent(values.email)}`);
            })
         );
      } catch (error) {
         if (error.response || error.response.data || error.response.data.errors) {
            const errorMessage = error.response.data.errors.message;
            Toast.error(errorMessage);
         }
      }
   };

   const handleTogglePassword = () => {
      if (type === "password") {
         setIcon(eye);
         setType("text");
      } else {
         setIcon(eyeOff);
         setType("password");
      }
   };

   const handleToggleConfirmPassword = () => {
      if (confirmType === "password") {
         setConfirmIcon(eye);
         setConfirmType("text");
      } else {
         setConfirmIcon(eyeOff);
         setConfirmType("password");
      }
   };

   return (
      <div className="p-8 p-md-10 pt-xl-10 pb-xl-20 pl-xl-40 pr-xl-40">
         <Link
            to="/auth/registration"
            className="d-flex align-items-center"
            style={{
               color: "#5492FF",
               fontSize: "16px",
               fontWeight: 500,
               cursor: "pointer"
            }}
         >
            <img src={BackIcon} alt="Go back" style={{ marginRight: "10px" }} />
            Go back to profile selection
         </Link>
         <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
         >
            {({ values, handleChange, setFieldValue, errors, touched }) => (
               <Form>
                  <div
                     style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "40px",
                        gap: "32px"
                     }}
                  >
                     <p
                        style={{
                           fontSize: "26px",
                           color: "#4158CF",
                           fontWeight: 600,
                           margin: "0px"
                        }}
                     >
                        Create an account{" "}
                        <span style={{ color: "#5492FF" }}>as an Expert</span>
                     </p>
                     <div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 <span style={{ color: "red" }}>* </span>
                                 Email
                              </label>
                              <Field
                                 placeholder="yourmail@gmail.com"
                                 className={`form-control`}
                                 type="email"
                                 name="email"
                                 autoComplete="off"
                              />
                              {errors.email && touched.email && (
                                 <div className="text-danger">{errors.email}</div>
                              )}
                           </div>
                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 <span style={{ color: "red" }}>* </span>
                                 Password
                              </label>

                              <div className="password-section">
                                 <Field
                                    placeholder="Password"
                                    type={type}
                                    name="password"
                                    className="form-control"
                                 />

                                 <img
                                    className="eyeButton"
                                    onClick={handleTogglePassword}
                                    src={EyeInvisible}
                                    alt="showPasswordIcon"
                                 />
                              </div>

                              {errors.password && touched.password && (
                                 <div className="text-danger">{errors.password}</div>
                              )}
                           </div>
                           <div className="form-group fv-plugins-icon-container">
                              <label
                                 style={{
                                    fontSize: "16px",
                                    color: "#3A3737",
                                    fontWeight: 500,
                                    marginBottom: "10px"
                                 }}
                              >
                                 <span style={{ color: "red" }}>* </span>
                                 Confirm password
                              </label>
                              <div className="password-section">
                                 <Field
                                    placeholder="Password"
                                    type={confirmType}
                                    name="confirmPassword"
                                    className="form-control"
                                 />

                                 <img
                                    className="eyeButton"
                                    onClick={handleToggleConfirmPassword}
                                    src={EyeInvisible}
                                    alt="showPasswordIcon"
                                 />
                              </div>

                              {errors.confirmPassword && touched.confirmPassword && (
                                 <div className="text-danger">{errors.confirmPassword}</div>
                              )}
                           </div>
                        </div>
                     </div>

                     <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
                        <button type="submit" className={`btn sign-btn font-weight-bold my-3`}>
                           {loading ? <Spin /> : "Continue"}
                        </button>
                     </div>
                  </div>
               </Form>
            )}
         </Formik>
      </div>
   );
}
export default RegistrationOfHumanitarian;
